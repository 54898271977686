import { getAuth } from "firebase/auth"
import 'firebase/compat/firestore'
import { getStorage } from "firebase/storage"
import { initializeApp } from 'firebase/app'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
}

const firebase = initializeApp(config)

export const auth = getAuth(firebase)
export const storage = getStorage(firebase)

export default firebase
